import styled from 'styled-components';
import Img from 'gatsby-image';
import IndexLayout from '../layouts';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { AppTitle, NavBar, TextCycler } from '../components/ui';
import { Link, graphql } from 'gatsby';
import { BottomNavSegment, Footer, OurShopifyInsightsSegment } from '../components/PageSegments';
import favicon from '../content/img/icon/favicon.ico';

const ShopifyPage = data => {
  console.log('data ecommerce: ', data);
  return (
    <IndexLayout>
      <Helmet>
        <title>Summary of Base10 Trend: Commerce enablement tools for the retail industry</title>
        <link rel="icon" href={favicon} />
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <meta charSet="UTF-8" />
        <link rel="canonical" href="https://base10.vc/ecommerce-shopify-saas/" />
        <meta name="og:url" content="https://base10.vc/ecommerce-shopify-saas/" />
        <meta
          name="og:title"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/base10/image/upload/v1597203731/shopify-research/Shopify_redes_wayg8l.jpg"
        />
        <meta name="og:image:width" content="1200" />
        <meta name="og:image:height" content="630" />
        <meta
          name="og:description"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta
          name="description"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta
          name="twitter:title"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta
          name="twitter:description"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/base10/image/upload/v1597203731/shopify-research/Shopify_redes_wayg8l.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Summary of Base10 Trend: Commerce enablement tools for the retail industry"
        />
        <meta name="twitter:site" content="@Base10Partners" />
        <meta name="twitter:creator" content="@Base10Partners" />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Main>
        <NavBar invert />
        <OurShopifyInsightsSegment data={data} />
        <Footer />
        <BottomNavSegment />
      </Main>
    </IndexLayout>
  );
};

export const query = graphql`
  query ShopifyPageQuery {
    allAmountOfFundingJson {
      nodes {
        amount
        id
        year
      }
    }
    allCompaniesMapJson {
      nodes {
        id
        lat
        logo_url
        long
        name
      }
    }
    allCircleNumbersJson {
      nodes {
        id
        number
        title
      }
    }
    allCumulativeRaisedJson {
      nodes {
        amount
        id
        year
      }
    }
    allKeyInsightsListJson {
      nodes {
        id
        head
        ordinal
        text
      }
    }
    allMostActiveInvestorsJson {
      nodes {
        id
        avg_check
        investments
        investor_image
        investor_name
        key_investors
      }
    }
    allNumberOfUnicornsJson {
      nodes {
        companies
        id
        label
        number
        year
      }
    }
    allResearchCompaniesJson {
      nodes {
        id
        section_title
        tooltip
        rows {
          amount_raised
          description
          founded
          founders {
            name
            link
          }
          hq_location
          key_investors
          name
          segment
          stage
          website
          people
        }
      }
    }
    allTopCompaniesInvestorsJson {
      nodes {
        avg_check
        id
        investments
        investor_image
        investor_name
        key_investors
      }
    }
    allShopifyDataJson {
      nodes {
        america
        canada
        europe
        id
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* height: 2000px; */
  /* background: grey; */
`;

export default ShopifyPage;
